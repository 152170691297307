import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminComponent } from "./admin/admin.component"
import { LoginComponent } from './auth-page/login/login.component';
import { User_Lists_Component } from './users-detalis/user-lists/user-lists.component';
import { Create_UserComponent } from './users-detalis/create-user/create-user.component';
import { ViewUserComponent } from './users-detalis/view-user/view-user.component';
import { ViewDetailsComponent } from './auth-page/view-user/view-user.component'
import { Error404Component } from './error404/error404.component';
import { AuthGuardService } from './auth-page/auth-guard.service.ts﻿﻿';
const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'check/:id/details', component: ViewDetailsComponent },
  {
    path: 'admin', component: AdminComponent,canActivate : [AuthGuardService] , children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'user-list', component: User_Lists_Component },
      { path: 'create', component: Create_UserComponent },
      { path: 'view/:id/details', component: ViewUserComponent }     
    ]
  },
  { path: '403', component: Error404Component },
  { path: '**', redirectTo: '/403' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
