import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class UersService {

    // Define API
    apiURL = environment.apiURL;

    constructor(private http: HttpClient) { }

    getUsers(): Observable<any> {
        return this.http.get(this.apiURL+'/userDetails')
            .pipe(catchError(this.handleError))
    }

    // HttpClient API get() method => Fetch User
    getUser(id): Observable<any> {
        return this.http.get(this.apiURL + '/userDetails/' + id)
            .pipe(catchError(this.handleError))
    }

    // HttpClient API post() method => Create User
    createUser(users): Observable<any> {
        return this.http.post(this.apiURL + '/userDetails/create', JSON.stringify(users))
            .pipe(catchError(this.handleError))
    }

    // HttpClient API delete() method => Delete User
    deleteUser(id) {
        return this.http.delete(this.apiURL + '/userDetails/' + id)
            .pipe(catchError(this.handleError))
    }

    handleError(errors) {
        let errorMessage = errors.error.component.error;
        window.alert(errorMessage)
        return throwError(errorMessage);

    }
   
}