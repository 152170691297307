<div style="text-align:center">
	<div>
		<webcam [height]="400" [width]="300" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
		 [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" (cameraSwitched)="cameraWasSwitched($event)"
		 (initError)="handleInitError($event)"></webcam>
		<br/>
		<div class="actionBtn" >
			<span (click)="triggerSnapshot();" title="Take Picture">📸</span>&nbsp;
			<span  (click)="showNextWebcam(true);" title="Switch Camera">🔁</span>
		</div>	
	</div>
</div>

<h4 *ngIf="errors.length > 0">Messages:</h4>
<ul *ngFor="let error of errors">
	<li>{{error | json}}</li>
</ul>