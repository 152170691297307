<div class="login-page">
  <div class="login-box">
    <!-- /.login-logo -->
    <div class="card card-outline card-primary">
      <div class="card-header text-center">
        <img src="assets/images/akram-logo.png" class="img-width" alt="logo" />
        <b class="h3 main-text">AKRAM S.A.R.L.</b>
      </div>
      <div class="card-body">
        <p class="login-box-msg">Sign in to start your session</p>
        <p class="login-box-msg">Vaccination Process</p>
        <form>
          <div class="input-group mb-3">
            <input  type="text" [(ngModel)]="username" name="userName" id="userName" class="form-control" placeholder="Email / User Name" required >
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input  type="password" [(ngModel)]="password" name="userPassword" id="userPassword" class="form-control" placeholder="***********"   required>
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="row">            
            <!-- /.col -->
            <div class="col-6">
              <button  type="submit" class="btn btn-block btn-primary btn-lg" value="Login" (click)="onLogin()" [disabled]="!password || !username">Login</button>
            </div>
            <!-- /.col -->
          </div>
        </form>

      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </div>
</div>
<app-loader></app-loader>