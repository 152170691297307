import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
 
@Injectable()
export class AuthGuardService implements CanActivate {
 
    constructor(private router:Router,private toastr: ToastrService) {
 
    }
 
    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean|UrlTree { 
        if (!sessionStorage.getItem('token')) {
            this.toastr.error('You are not allowed to view this page. You are redirected to login Page');
            
            this.router.navigate(["/"],{ queryParams: { retUrl: route.url} });
            return false;
 
            //var urlTree = this.router.createUrlTree(['login']);
            //return urlTree;
        } 
 
        return true;
    }
 
}