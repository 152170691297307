import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { UersService } from "../user.service";
import pdfmake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
import { Constants } from 'src/app/shared/constant';
import { environment } from 'src/environments/environment';
pdfmake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss'],
  providers: [DatePipe]
})
export class ViewUserComponent implements OnInit {

  constructor(
    public actRoute: ActivatedRoute,
    private uersService: UersService,
    public datepipe: DatePipe) { }
  id = this.actRoute.snapshot.params['id'];
  userData: any = {};
  drNameList: any = Constants.DR_NAMES;
  watermark;
  hStemp;
  drData: any = {};
  isLoading: boolean = false;
  QRURL: any;
  ngOnInit() {
    this.getUserDetails(this.id);
    this.watermark = 'assets/images/akram-logo.png'
    this.hStemp = 'assets/images/hstamp.png'
    this.QRURL = environment.QRcodeURL
  }
  getUserDetails(id) {
    this.isLoading = true;
    this.uersService.getUser(id).subscribe((data) => {
      this.userData = data.result;
      this.isLoading = false;
    })
  }
  getBase64ImageFromURL(url) {
    debugger
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");
        var url = dataURL.replace(/^"(.+)"$/, '$1');
        resolve(url);
      };

      img.onerror = error => {
        reject(error);
      };
      if(url){
        img.src = url;
      }else{
        img.src ='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII='
      }
     
    });
  }

  async generatePdf() {
    this.QRURL = this.QRURL + this.userData.id + "/details"
    this.isLoading = true;
    var result = this.drNameList.find(o => o.Name == this.userData.drName);
    this.drData = result;

    var dd = {
      pageSize: 'A4',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [20, 20, 20, 80],
      footer: function (currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element

        return [
          { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 595 - 10, y2: 10, lineWidth: 1 }] },

          { text: 'STE.AKRAM S.A.R.L. | Direction Generale | B.P. 16654 | No 32, Av. Colonel Ebeya | C/ Gombe | Contact +243 890 010 632/ 890 010 629', alignment: 'center', style: 'small' },
          { text: 'E-mail : groupakram@yahoo.com', alignment: 'center', style: 'small' },
          { text: 'Kinshasa / R.D.Congo', alignment: 'center', style: 'small', bold: true },

        ]
      },
      background: [{
        image: await this.getBase64ImageFromURL(this.watermark),
        width: 600,
        height: 700,
        opacity: 0.09,
        absolutePosition: { x: 20, y: 20 },
      }],
      content: [
        {
          columns: [
            {
              image: await this.getBase64ImageFromURL(this.watermark),
              width: 100,
              height: 100,
            },
            {
              type: 'none',
              ul: [

                {
                  text: 'CENTER HOSPITALIER AKRAM',
                  style: 'header',
                  alignment: 'center',
                },
                {
                  text: 'AKRAM S.A.R.L.',
                  style: 'cclor',
                  alignment: 'center',
                }
              ],

            },
            {
              image: await this.getBase64ImageFromURL(this.watermark),
              width: 100,
              height: 100,
            }
          ]
        },
        {
          text: '\n\n'
        },
        {
          columns: [
            {

            },
            {
              text: 'COVID-19 VACCINATION CARD',
              alignment: 'center',
              style: 'cclor'
            },
            {

            }
          ]
        },
        {
          text: '\n\n'
        },
        {
          columns: [
            {

            },
            {

            },
            {
              image: await this.getBase64ImageFromURL(this.userData.userImage) ,
              width: 100,
              height: 100,
             
            }
          ]
        },
        {
          text: '\n\n'
        },
        { text: '' },
        {
          style: 'tableExample',
          color: '#444',
          margin: [65, 5],
          table: {
            widths: [30, 30, 45, 100, 100, 100],
            alignment: 'center',
            headerRows: 2,
            // keepWithHeaderRows: 1,
            body: [
              [{ text: this.userData.fname + ' ' + this.userData.lname, style: 'tableHeader', colSpan: 6, alignment: 'center' }, {}, {}, {}, {}, {}],
              [{ text: 'SEX' }, { text: this.userData.gender }, { text: 'D.O.B' }, { text: this.datepipe.transform(this.userData.dateofBirth, 'dd-MMM-yyyy') }, { text: this.userData.identityDocument }, { text: this.userData.documentNumber }],

            ]
          }
        },
        {
          text: '\n\n'
        },
        { text: '' },
        {
          style: 'tableExample',
          color: '#444',
          margin: [65, 5],
          table: {
            widths: [190, 115, 130],
            headerRows: 2,
            // keepWithHeaderRows: 1,
            body: [
              [{ text: 'Vaccine Name', style: 'tableHeader', alignment: 'center' }, { text: 'Date', style: 'tableHeader', alignment: 'center' }, { text: 'Batch Number', style: 'tableHeader', alignment: 'center' }],
              [{ text: this.userData.vaccineName, style: 'tableHeader', alignment: 'center' }, { text: this.datepipe.transform(this.userData.dose1VassionationDate, 'dd-MMM-yyyy'), style: 'tableHeader', alignment: 'center' }, { text: this.userData.dose1BatchNo, style: 'tableHeader', alignment: 'center' },],
              [{ text: this.userData.vaccineName2 !=null ?  this.userData.vaccineName2 : this.userData.vaccineName , style: 'tableHeader', alignment: 'center' }, { text: this.datepipe.transform(this.userData.dose2VassionationDate, 'dd-MMM-yyyy'), style: 'tableHeader', alignment: 'center' }, { text: this.userData.dose2BatchNo, style: 'tableHeader', alignment: 'center' },],
              [{ text: this.userData.boosterDose, style: 'tableHeader', alignment: 'center' }, { text: this.datepipe.transform(this.userData.dose3VassionationDate, 'dd-MMM-yyyy'), style: 'tableHeader', alignment: 'center' }, { text: this.userData.dose3BatchNo, style: 'tableHeader', alignment: 'center' },],
              [{ text: this.userData.vaccineName4, style: 'tableHeader', alignment: 'center' }, { text: this.datepipe.transform(this.userData.dose4VassionationDate, 'dd-MMM-yyyy'), style: 'tableHeader', alignment: 'center' }, { text: this.userData.dose4BatchNo, style: 'tableHeader', alignment: 'center' },],
            ]
          }
        },
        {
          text: '\n\n\n\n'
        },
        {
          // [left, top, right, bottom]
          margin: [15, 0,5,0],
          columns: [
            {
              qr: this.QRURL, fit: '150'
            },
            {
              margin: [5, 5],
              image: await this.getBase64ImageFromURL(this.drData.DrStamp),
              width: 120,
              height: 90,

            },
            {
              type: 'none',
             
              ul: [
               
                {
                  image: await this.getBase64ImageFromURL(this.drData.SignatureIMG),
                  width: 100,
                  height: 60,
                },
                { text:'__________________________________' },
                {
                  text: this.userData.drName,
                
                },
                {
                  text: 'CNOM:' + this.userData.CNOMNumber,
                },
                {
                  image: await this.getBase64ImageFromURL(this.drData.DrHospitalName),
                  width: 200,
                  height: 70,
                }
              ]
            }
          ]
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          color: 'red',
          margin: [0, 35, 0, 0]
        },
        subheader: {
          fontSize: 15,
          bold: true
        },
        quote: {
          italics: true
        },
        small: {
          fontSize: 9
        },
        cclor: {
          color: 'blue',
        }
      }
    };
    this.isLoading = false;
    pdfmake.createPdf(dd).download(this.userData.fname + this.userData.lname);

  }

}
