import { Component, OnInit } from '@angular/core';
import { UersService } from '../users-detalis/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  userCount:any;
  isLoading: boolean = false;
  toggleProBanner(event) {
    event.preventDefault();
    document.querySelector('body').classList.toggle('removeProbanner');
  }

  constructor(public uersService: UersService) { }

  ngOnInit() {
    this.getUsersList()
  }
  getUsersList() {
    this.isLoading=true;
    this.uersService.getUsers().subscribe((data) => {
      let user:any = data.result;
      this.userCount=user.length;
      this.isLoading=false;
    })
  }


}
