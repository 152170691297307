import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UersService } from "../user.service";
@Component({
  selector: 'app-basic-table',
  templateUrl: './user-lists.component.html',
  styleUrls: ['./user-lists.component.scss']
})
export class User_Lists_Component implements OnInit {
  userList: any = [];
  p: number = 1;
  isLoading: boolean = false;
  constructor(private router: Router, public uersService: UersService,private toastr: ToastrService) { }

  ngOnInit() {
    this.getUsersList()
  }
  openModal(Id?) {
    this.router.navigate(['./admin/create']);

  }
  getUsersList() {
    this.isLoading=true;
    this.uersService.getUsers().subscribe((data) => {    
      this.userList = data.result;
      this.isLoading=false;
    })
  }
  deleteUser(id){
    if (confirm('Are you sure you want to delete user?')) {
      this.isLoading=true;
      this.uersService.deleteUser(id).subscribe((data: {}) => {   
        this.toastr.success('Delete user successfully', 'Admin!'); 
        this.isLoading=false;
        this.getUsersList()
      })
    } else {
      this.isLoading=false;
    }
  }

}
