import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {WebcamImage} from 'ngx-webcam';
import {Constants} from '../../shared/constant'
import { Observable } from 'rxjs';
import { UersService } from "../user.service";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class Create_UserComponent implements OnInit {
  public webcamImage: WebcamImage = null;
  cemera:boolean=false;
  maxDate: Date;
  model: any = {};
  drNameList:any=Constants.DR_NAMES;
  SignatureIMG="assets/images/signature3.png";
  isLoading: boolean = false;
  constructor(private uersService: UersService, public router: Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.maxDate = new Date();
  }
  openCemera(){   
    this.cemera=true;
  }
  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    this.cemera=false;
    console.log(this.webcamImage.imageAsDataUrl)
  }
  drNameSelected(){    
    var result = this.drNameList.find(o => o.Name ==this.model.drName);
    this.SignatureIMG=result.SignatureIMG;
  }
  onSubmit(){
    if(1==1){
     this.isLoading=true;
      this.model.isDeleted="false";
      console.log("UserData",this.model)
     this.model.userImage=this.webcamImage.imageAsDataUrl
      this.uersService.createUser(this.model).subscribe((data: {}) => {
        this.toastr.success('Create user successfully', 'Admin!');
        this.isLoading=false;
        this.router.navigate(['/admin/user-list'])
      })
    }else{
      
    }
    
  }

}

// create Image Form based Code
// handleImage(webcamImage: WebcamImage) {
//   this.webcamImage = webcamImage;

//   const arr = this.webcamImage.imageAsDataUrl.split(",");
//   const mime = arr[0].match(/:(.*?);/)[1];
//   const bstr = atob(arr[1]);
//   let n = bstr.length;
//   const u8arr = new Uint8Array(n);
//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }
//   const file: File = new File([u8arr], 'abc.jpeg', { type: "image/jpeg" })
//   console.log('file', file);
// }