
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h3>VIEW COVID-19 VACCINATION USER DETAILS</h3>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">View User</li>
        </ol>
      </div>
    </div>
  </div><!-- /.container-fluid -->
</section>
<div class="row">

  <div class="col-12 grid-margin">
    <div class="card">
      <div class="card-body">
       
        <div class="row" *ngIf="userData.userImage">
          <div class="col-12 text-right">
            <img [src]="userData.userImage" alt="image" width="213" height="213"/>
          </div>
        </div>
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td colspan="4" class="text-center"><strong>{{userData.fname}} {{userData.lname}}</strong></td>
            </tr>
            <tr>
              <td>Sex</td>
              <td>{{userData.gender}}</td>
              <td>D.O.B</td>
              <td>{{userData.dateofBirth  | date:'d-MMM-y'}}</td>
            </tr>
            <tr>
              <td>{{userData.identityDocument}}</td>
              <td>{{userData.documentNumber}}</td>
              <td>City</td>
              <td>{{userData.city}}</td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-12">&nbsp;
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th  class="text-center"><strong>Vaccine Name</strong> </th>
                  <th scope="col" class="text-center"><strong>Date</strong> </th>
                  <th scope="col" class="text-center"><strong> Batch Number</strong></th>
                 
                </tr>
              </thead>
              <tbody>
                <tr>
                
                  <td>{{userData.vaccineName}}</td>
                  <td>{{userData.dose1VassionationDate  | date:'d-MMM-y'}}</td>
                  <td>{{userData.dose1BatchNo}}</td>
                </tr>
                <tr>
                
                  <td>{{userData.vaccineName2 !=null ? userData.vaccineName2 :userData.vaccineName }}</td>
                  <td>{{userData.dose2VassionationDate | date:'d-MMM-y'}}</td>
                  <td>{{userData.dose2BatchNo}}</td>
                </tr>  
                <tr *ngIf="userData.boosterDose">
                
                  <td>{{userData.boosterDose}}</td>
                  <td>{{userData.dose3VassionationDate | date:'d-MMM-y'}}</td>
                  <td>{{userData.dose3BatchNo}}</td>
                </tr>  
                <tr *ngIf="userData.vaccineName4">
                
                  <td>{{userData.vaccineName4}}</td>
                  <td>{{userData.dose4VassionationDate | date:'d-MMM-y'}}</td>
                  <td>{{userData.dose4BatchNo}}</td>
                </tr>                 
              </tbody>
            </table>
            <div class="col-md-10"></div>
              <div class="col-md-2 float-right">
                <button type="button" (click)="generatePdf()" title="Download PDF File" class="btn btn-block btn-primary btn-sm float-right">Download PDF</button>
              </div>                       
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loading" *ngIf="isLoading">Loading&#8230;</div>