<nav class="main-header navbar navbar-expand navbar-white navbar-light nav-color">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
    </li>    
  </ul>

  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">
    <!-- Navbar Search -->
    
    <!-- Messages Dropdown Menu -->
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#">
        <i class="fas fa-user"></i>
      Admin
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">        
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item dropdown-footer" (click)="signout()">Signout</a>
      </div>
    </li>
    
  </ul>
</nav>