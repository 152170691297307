import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { User_Lists_Component } from './user-lists/user-lists.component';
import { RouterModule, Routes } from '@angular/router';
import { Create_UserComponent } from './create-user/create-user.component';
import { CameraComponent } from './camera/camera.component';
import { WebcamModule } from 'ngx-webcam';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {NgxPaginationModule} from 'ngx-pagination';
import { ViewUserComponent } from './view-user/view-user.component';
const routes: Routes = [
  { path: 'user-list', component: User_Lists_Component },
  { path: 'create', component: Create_UserComponent },
  { path: 'view', component: ViewUserComponent } 
]

@NgModule({
  declarations: [User_Lists_Component,Create_UserComponent,CameraComponent,ViewUserComponent],
  imports: [
    CommonModule,
    FormsModule,
    WebcamModule,
    NgxPaginationModule,
    BsDatepickerModule.forRoot(),
    RouterModule.forChild(routes),
  ]
})
export class UserDetailsModule { }
