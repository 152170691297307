import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
@Injectable({
    providedIn: 'root'
})

export class LoginService {
   
    // Define API
    apiURL = environment.apiURL;
    isLoading:boolean=false;
    constructor(private http: HttpClient, private toastr: ToastrService) { }


    // HttpClient API post() method => Create employee
    loginUser(user): Observable<any> {
        return this.http.post(this.apiURL + '/users/authenticate', JSON.stringify(user))
            .pipe(catchError(this.handleError))
    }
    handleError(errors) {      
        this.isLoading = false;  
        let errorMessage = errors.error.component.error;
        window.alert(errorMessage)
        return throwError(errorMessage);

    }

}