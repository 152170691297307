import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './auth-page/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import {UserDetailsModule} from './users-detalis/user-details.module'
import { ViewDetailsComponent } from './auth-page/view-user/view-user.component'
import { Error404Component } from './error404/error404.component';
import { AuthGuardService } from './auth-page/auth-guard.service.ts﻿﻿';
import { QRCodeModule } from 'angularx-qrcode';
import { LoaderService } from './shared/loader/loader.service';
import { LoaderComponent } from './shared/loader/loader.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,   
    AdminComponent,
    LoginComponent,
    ViewDetailsComponent,
    Error404Component,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, 
    ToastrModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,  
    FormsModule,
    ReactiveFormsModule,      
    UserDetailsModule,
    QRCodeModule      
  ],
  exports:[
    LoaderComponent
  ],
  providers: [LoaderService,{ provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },AuthGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
