import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { UersService } from "./user.service";
import pdfmake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
import { Constants } from 'src/app/shared/constant';
import { environment } from 'src/environments/environment';
pdfmake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss'],
  providers: [DatePipe]
})
export class ViewDetailsComponent implements OnInit {

  constructor(
    public actRoute: ActivatedRoute,
    private uersService: UersService,
    public datepipe: DatePipe) { }
  id = this.actRoute.snapshot.params['id'];
  userData: any = {};
  drNameList:any=Constants.DR_NAMES;
  hStemp;
  drData:any={};
  watermark;  
  QRURL:any;
  isLoading: boolean = false;
  ngOnInit() {   
    this.QRURL=environment.QRcodeURL;
    this.getUserDetails(this.id);
    this.watermark = 'assets/images/akram-logo.png'
    this.hStemp = 'assets/images/hstamp.png'
  }
  getUserDetails(id) {
    this.isLoading = true;
    this.uersService.getUser(id).subscribe((data) => {      
      this.userData = data.result;
      var result = this.drNameList.find(o => o.Name == this.userData.drName);
      this.drData = result;
      this.QRURL = this.QRURL + this.userData.id + "/details";
      this.isLoading = false;
    })
  }
 

}
