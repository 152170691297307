import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})

export class UersService {

    // Define API
    apiURL = environment.apiURL;

    constructor(private http: HttpClient) { }

   
    // HttpClient API get() method 
    getUser(id): Observable<any> {
        return this.http.get(this.apiURL + '/userDetails/' + id)
            .pipe()
    }

    

   
}