<div>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">User Vaccinated  List</h1>
        </div><!-- /.col -->
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a>Home</a></li>
            <li class="breadcrumb-item active">User List</li>
          </ol>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-10">
         
        </div>
        <div class="col-lg-2 pb-2 float-right">
          <button type="button" class="btn btn-block btn-primary btn-sm " (click)="openModal()">Add User</button>
        </div>
      </div>
      <!-- /.row -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">User List Table</h3>
          
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>
                      User
                    </th>
                    <th>
                      User Name
                    </th>
                    <th>
                      Vaccine Name
                    </th>
                    <th>
                      Date Of 1st Dose
                    </th>
                    <th>
                      Date Of 2nd Dose
                    </th>
                    <th>
                      Date Of 3rd Dose
                    </th>
                    <th>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody *ngIf="userList.length > 0">
                  <tr  *ngFor="let item of userList | paginate: { itemsPerPage: 5, currentPage: p }">
                    <td class="py-1">
                      <img  class="table-avatar" [src]="item.userImage" alt="image">
                      <img  />
                    </td>
                    <td>
                     {{item.fname}} {{item.lname}}
                    </td>
                    <td>
                     {{item.vaccineName}}
                    </td>
                    <td>
                      {{item.dose1VassionationDate | date:'d-MMM-y'}}
                    </td>
                    <td>
                      {{item.dose2VassionationDate| date:'d-MMM-y'}}
                    </td>
                    <td *ngIf="item.boosterDose">
                      {{item.dose3VassionationDate| date:'d-MMM-y'}}-({{item.boosterDose}})
                    </td>
                    <td *ngIf="!item.boosterDose">
                      -
                    </td>
                    <td>
                      <i class="fas fa-eye view-user pr-3" title="View User Details" routerLink="/admin/view/{{item.id}}/details"></i>
                      <i class="far fa-trash-alt view-user pr-3" title="Delete User" (click)="deleteUser(item.id)"></i>
                    </td>
                  </tr>
                
                </tbody>
                <tbody *ngIf="userList.length == 0">
                  <tr>
                    <td colspan="6"><p class="text-center">No User Data Available</p></td>                  
                  </tr>
                </tbody>
              </table>
              <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
      </div>

      <!-- /.row -->
    </div><!-- /.container-fluid -->
  </section>
</div>
<div class="loading" *ngIf="isLoading">Loading&#8230;</div>