<div>
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Vaccine Registration Form</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item">Home</li>
            <li class="breadcrumb-item active">Add User</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Add User Details</h3>
            </div>
            <!-- /.card-header -->
            <!-- form start -->
            <form class="form-sample" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
              <div class="card-body">
                <p class="card-description">
                  Personal info
                </p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label" for="fname">First Name</label>
                      <div class="col-sm-9">
                        <input type="text" class="form-control" name="fname" maxlength="30" [(ngModel)]="model.fname"
                          #fname="ngModel" [ngClass]="{ 'is-invalid': f.submitted && fname.invalid }" required />
                      </div>
                      <div *ngIf="f.submitted && fname.invalid" class="invalid-feedback">

                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Last Name</label>
                      <div class="col-sm-9">
                        <input type="text" class="form-control" name="lname" maxlength="30" [(ngModel)]="model.lname"
                          #lname="ngModel" [ngClass]="{ 'is-invalid': f.submitted && lname.invalid }" required />
                      </div>
                      <div *ngIf="f.submitted && lname.invalid" class="invalid-feedback">

                      </div>


                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Gender</label>
                      <div class="col-sm-9">
                        <select class="form-control" name="gender" [(ngModel)]="model.gender" #gender="ngModel"
                          [ngClass]="{ 'is-invalid': f.submitted && gender.invalid }" required>
                          <option value="" disabled selected>Select Gender</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>

                        </select>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Date of Birth</label>
                      <div class="col-sm-9">
                        <input type="text" readonly placeholder="Date of Birth" [maxDate]="maxDate" class="form-control"
                          bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY',isAnimated: true}" name="dob"
                          [(ngModel)]="model.dateofBirth" #dob="ngModel"
                          [ngClass]="{ 'is-invalid': f.submitted && dob.invalid }" required>
                      </div>
                      <div *ngIf="f.submitted && dob.invalid" class="invalid-feedback">

                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Identity Document</label>
                      <div class="col-sm-9">
                        <select class="form-control" name="documentid" [(ngModel)]="model.identityDocument"
                          #documentid="ngModel" [ngClass]="{ 'is-invalid': f.submitted && documentid.invalid }"
                          required>
                          <option value="" disabled selected>Select Identity Document</option>
                          <option value="Passport">Passport</option>
                          <option value="ID Card">ID Card</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <div class="col-sm-9">
                        <input class="form-control" name="Identity" [(ngModel)]="model.documentNumber"
                          #Identity="ngModel" [ngClass]="{ 'is-invalid': f.submitted && Identity.invalid }" required />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                </div>
                <p class="card-description btn-primary" style="padding-left: 1rem;">
                  <strong>Vaccine info</strong>
                </p>
                <br>
                <div class="row">
                  <div class="col-sm-2">

                  </div>
                  <div class="col-sm-2">
                    <a class="btn btn-success" data-toggle="collapse" href="#dose1" role="button" aria-expanded="false"
                      aria-controls="dose1">
                      Dose-1
                    </a>
                  </div>
                  <div class="col-sm-2">
                    <a class="btn btn-success" data-toggle="collapse" href="#dose2" role="button" aria-expanded="false"
                      aria-controls="dose2">
                      Dose-2
                    </a>
                  </div>
                  <div class="col-sm-2">
                    <a class="btn btn-warning" data-toggle="collapse" href="#booster1" role="button"
                      aria-expanded="false" aria-controls="booster1">
                      Booster Dose-1
                    </a>
                  </div>
                  <div class="col-sm-2">
                    <a class="btn btn-warning" data-toggle="collapse" href="#booster2" role="button"
                      aria-expanded="false" aria-controls="booster2">
                      Booster Dose-2
                    </a>
                  </div>
                </div>
                <br>
                <!-- Dose-1 Start -->
                <div class="collapse" id="dose1">
                  <div class="card card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Vaccine / Dose Name  - 1</label>
                          <div class="col-sm-9">
                            <select class="form-control" name="vaccinename" [(ngModel)]="model.vaccineName"
                              #vaccinename="ngModel" [ngClass]="{ 'is-invalid': f.submitted && vaccinename.invalid }"
                              required>
                              <option value="" disabled selected>Select Vaccine Name</option>
                              <option>Astrazeneca</option>
                              <option>Moderna</option>
                              <option>Pfizer</option>
                              <option>Johnson & Johnson/Janssen</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">City</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" name="city" [(ngModel)]="model.city" #city="ngModel"
                              [ngClass]="{ 'is-invalid': f.submitted && city.invalid }" required />
                          </div>
                        </div>
                        <div *ngIf="f.submitted && city.invalid" class="invalid-feedback">

                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Dose - 1 Batch No.</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" name="Batch1" [(ngModel)]="model.dose1BatchNo"
                              #Batch1="ngModel" [ngClass]="{ 'is-invalid': f.submitted && Batch1.invalid }" required />
                          </div>
                        </div>
                        <div *ngIf="f.submitted && Batch1.invalid" class="invalid-feedback">

                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Dose - 1 Vassionation Date</label>
                          <div class="col-sm-9">
                            <input type="text" readonly placeholder="Vassionation Date" [maxDate]="maxDate"
                              class="form-control" bsDatepicker
                              [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY',isAnimated: true}" name="dose1"
                              [(ngModel)]="model.dose1VassionationDate" #dose1="ngModel"
                              [ngClass]="{ 'is-invalid': f.submitted && dose1.invalid }" required />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Dose-1 END -->

                <!-- Dose-2 Start -->
                <div class="collapse" id="dose2">
                  <div class="card card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Vaccine / Dose Name  - 2</label>
                          <div class="col-sm-9">
                            <select class="form-control" name="vaccinename2" [(ngModel)]="model.vaccineName2">
                              <option value="" disabled selected>Select Vaccine Name</option>
                              <option>Astrazeneca</option>
                              <option>Moderna</option>
                              <option>Pfizer</option>
                              <option>Johnson & Johnson/Janssen</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Dose - 2 Batch No.</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" name="Batch2" [(ngModel)]="model.dose2BatchNo"
                              #Batch2="ngModel" [ngClass]="{ 'is-invalid': f.submitted && Batch2.invalid }" required />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Dose - 2 Vassionation Date</label>
                          <div class="col-sm-9">
                            <input type="text" readonly placeholder="Vassionation Date" [maxDate]="maxDate"
                              class="form-control" bsDatepicker
                              [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY',isAnimated: true}" name="dose2"
                              [(ngModel)]="model.dose2VassionationDate" #dose2="ngModel"
                              [ngClass]="{ 'is-invalid': f.submitted && dose2.invalid }" required />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Dose-2 END -->


                <!-- Booster-1 Start -->
                <div class="collapse" id="booster1">
                  <div class="card card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Vaccine / Booster Dose - 1</label>
                          <div class="col-sm-9">
                            <select class="form-control" name="vaccinename" [(ngModel)]="model.boosterDose">
                              <option value="" disabled selected>Select Booster Dose Name</option>
                              <option>Astrazeneca</option>
                              <option>Moderna</option>
                              <option>Pfizer</option>
                              <option>Johnson & Johnson/Janssen</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Booster Dose - 1 Batch No.</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" name="Batch2" [(ngModel)]="model.dose3BatchNo" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Booster Dose - 1 Vassionation Date</label>
                          <div class="col-sm-9">
                            <input type="text" readonly placeholder="Vassionation Date" [maxDate]="maxDate"
                              class="form-control" bsDatepicker
                              [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY',isAnimated: true}" name="dose2"
                              [(ngModel)]="model.dose3VassionationDate" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Booster-1 End -->

                 <!-- Booster-2 Start -->
                 <div class="collapse" id="booster2">
                  <div class="card card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Vaccine / Booster Dose - 2</label>
                          <div class="col-sm-9">
                            <select class="form-control" name="vaccinename4" [(ngModel)]="model.vaccineName4">
                              <option value="" disabled selected>Select Booster Dose Name</option>
                              <option>Astrazeneca</option>
                              <option>Moderna</option>
                              <option>Pfizer</option>
                              <option>Johnson & Johnson/Janssen</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Booster Dose - 2 Batch No.</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" name="Batch4" [(ngModel)]="model.dose4BatchNo" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Booster Dose - 2 Vassionation Date</label>
                          <div class="col-sm-9">
                            <input type="text" readonly placeholder="Vassionation Date" [maxDate]="maxDate"
                              class="form-control" bsDatepicker
                              [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY',isAnimated: true}" name="dose4"
                              [(ngModel)]="model.dose4VassionationDate" />
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>

                  <!-- Booster-2 End -->
                

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Dr. Name</label>
                      <div class="col-sm-9">
                        <select class="form-control" name="drname" [(ngModel)]="model.drName" #drname="ngModel"
                          (change)='drNameSelected()' [ngClass]="{ 'is-invalid': f.submitted && drname.invalid }"
                          required>
                          <option value="" disabled selected>Select Dr. Name</option>
                          <option *ngFor="let dr of drNameList;" [value]="dr.Name">{{dr.Name}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Dr.Signature Image</label>
                      <div class="col-sm-9">
                        <img [src]="SignatureIMG" *ngIf="model.drName" style="  display: block;
                     max-width:230px;
                     max-height:95px;
                     width: auto;
                     height: auto;">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">CNOM Number</label>
                      <div class="col-sm-9">
                        <input type="text" class="form-control" name="cnom" [(ngModel)]="model.CNOMNumber"
                          #cnom="ngModel" [ngClass]="{ 'is-invalid': f.submitted && cnom.invalid }" required />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Click to Open Webcame</label>
                      <div class="col-sm-9">
                        <i class="fas fa-camera-retro view-camera" (click)="openCemera()"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6" *ngIf="cemera">
                    <app-camera (pictureTaken)="handleImage($event)"></app-camera>
                  </div>
                  <div class="col-md-6" *ngIf="webcamImage">
                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">User Image</label>
                      <div class="col-sm-9">
                        <div class="snapshot" *ngIf="webcamImage">
                          <img [src]="webcamImage.imageAsDataUrl" class="user-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-10"></div>
                <div class="col-md-2 float-right">
                  <button type="submit" class="btn btn-block btn-primary btn-sm float-right">Save</button>
                </div>
              </div>
            </form>
          </div>
          <!-- /.card -->





        </div>
        <!--/.col (left) -->

      </div>
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  </section>
</div>