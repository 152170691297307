<div class="row">

  <div class="col-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-4 col-sm-2">
            <img src="assets/images/akram-logo.png" class="img-fluid " alt="image" width="180" height="180" />
          </div>
          <div class="col-4 col-sm-8 text-center" style="padding: 2%;">
            <h1 style="color: red;"  class="text-responsive">CENTER HOSPITALIER AKRAM</h1>
            <p style="color: #2424b1;">AKRAM S.A.R.L.</p>
          </div>
          <div class="col-4 col-sm-2  text-right">
            <img src="assets/images/akram-logo.png" class="img-fluid " alt="image" width="180" height="180" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
           <h5 class="c-color">COVID-19 VACCINATION CARD</h5>
          </div>
        </div>
        <div class="row" *ngIf="userData.userImage">
          <div class="col-12 text-right" style="padding: 1%;">
            <img [src]="userData.userImage" alt="image" width="150" height="150" />
          </div>
        </div>
        
        <div class="table-responsive">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="4" class="text-center"><strong>{{userData.fname}} {{userData.lname}}</strong></td>
              </tr>
              <tr>
                <td>Sex</td>
                <td>{{userData.gender}}</td>
                <td>DOB</td>
                <td>{{userData.dateofBirth | date:'d-MMM-y'}}</td>
              </tr>
              <tr>
                <td>{{userData.identityDocument}}</td>
                <td>{{userData.documentNumber}}</td>
                <td>City</td>
                <td>{{userData.city}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-12">&nbsp;
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="text-center"><strong>Vaccine Name</strong> </th>
                    <th scope="col" class="text-center"><strong>Date</strong> </th>
                    <th scope="col" class="text-center"><strong> Batch Number</strong></th>

                  </tr>
                </thead>
                <tbody>
                  <tr>

                    <td>{{userData.vaccineName}} - dose 1</td>
                    <td class="text-responsive">{{userData.dose1VassionationDate | date:'d-MMM-y'}}</td>
                    <td>{{userData.dose1BatchNo}}</td>
                  </tr>
                  <tr>

                    <td>{{userData.vaccineName2 !=null ? userData.vaccineName2 :userData.vaccineName }} - dose 2</td>
                    <td class="text-responsive">{{userData.dose2VassionationDate | date:'d-MMM-y'}}</td>
                    <td>{{userData.dose2BatchNo}}</td>
                  </tr>
                  <tr *ngIf="userData.dose3BatchNo">

                    <td>{{userData.boosterDose}} - booster dose 1</td>
                    <td class="text-responsive">{{userData.dose3VassionationDate | date:'d-MMM-y'}}</td>
                    <td>{{userData.dose3BatchNo}}</td>
                  </tr>
                  <tr *ngIf="userData.dose4BatchNo">

                    <td>{{userData.vaccineName4}} - booster dose 2</td>
                    <td class="text-responsive">{{userData.dose4VassionationDate | date:'d-MMM-y'}}</td>
                    <td>{{userData.dose4BatchNo}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-12">&nbsp;
          </div>
        </div>
        <div class="row">
         
          <div class="col-6 col-sm-6 text-right">
            <img [src]="drData.DrStamp" class="img-fluid " alt="image" width="180" height="180" />
            <qrcode [qrdata]="QRURL" [width]="180" [errorCorrectionLevel]="'L'"></qrcode>
          </div>
          <div class="col-6 col-sm-6 ">
            <img [src]="drData.SignatureIMG" class="img-fluid " alt="image" width="180" height="180" />
            <hr>
            <p class="text-responsive"> {{userData.drName}}</p>
            <p class="text-responsive"> CNOM: {{userData.CNOMNumber}}</p>           
            <div>
              <img [src]="drData.DrHospitalName" class="img-fluid" alt="image" width="180" height="180" />
            </div>
            
           
          </div>
        </div>     
        <hr>
        <div class="row">
          <div class="col-12 text-center">
           <p>STE.AKRAM S.A.R.L. | Direction Generale | B.P. 16654 | No 32, Av. Colonel Ebeya | C/ Gombe | Contact +243 890 010 632/ 890 010 629</p>
          <p>E-mail : groupakram@yahoo.com</p>
          <p>Kinshasa / R.D.Congo</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
