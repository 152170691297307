<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4 ">
  <!-- Brand Logo -->
  <a  class="brand-link">

    <span class="brand-text font-weight-light">AKRAM S.A.R.L.</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
   
    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
         
       
        <li class="nav-item">
          <a  routerLink="/admin/dashboard" routerLinkActive #dashboard="routerLinkActive" [ngClass]="{ 'active': dashboard.isActive }" class="nav-link">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p class="text">Dashboard</p>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/admin/user-list" routerLinkActive #tables="routerLinkActive" [ngClass]="{ 'active': tables.isActive }" class="nav-link">
            <i class="nav-icon fas fa-table"></i>
            <p>User List</p>
          </a>
        </li>       
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>