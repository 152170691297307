
    <app-navbar ></app-navbar>
    <div class="container-fluid page-body-wrapper">
      <app-sidebar ></app-sidebar>
      <div class="main-panel">
        <div class="content-wrapper">
        
          <div contentAnimate class="h-100">
            <router-outlet></router-outlet>
          </div>
        </div>
        <app-footer ></app-footer>
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  