import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string = "";
  password: string = "";
  isLoading: boolean = false;
  constructor(private router: Router, private loginService: LoginService,private toastr: ToastrService) { }

  ngOnInit() {
  }
  onLogin() {
    if (this.username && this.password) {
      let data = {
        "email": this.username,
        "password": this.password
      }
    
      this.loginService.loginUser(data).subscribe((data) => { 
        sessionStorage.setItem("token",data.result.access_token)      
        this.isLoading = false; 
        this.toastr.success('Now You Can Access Application', 'Hello Admin!');
        this.router.navigate(['./admin/dashboard']);
      })

    }


  }
}
