<div>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0"> Vaccination Dashboard</h1>
        </div><!-- /.col -->
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a>Home</a></li>
            <li class="breadcrumb-item active">Dashboard</li>
          </ol>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <!-- Small boxes (Stat box) -->
      <div class="row">        
        <!-- ./col -->
        <div class="col-lg-3 col-6">
          <div class="card">
            
            <!-- /.card-header -->
            <div class="card-body">
              <div class="small-box bg-warning">
                <div class="inner">
                  <h3>{{userCount}}</h3>
    
                  <p>User Vaccinated </p>
                </div>
                <div class="icon">
                  <i class="ion ion-person-add"></i>
                </div>
                <a class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
              </div>
            </div>
            
          </div>
          <!-- small box -->
          
        </div>
       
      </div>
      <!-- /.row -->
          
    </div><!-- /.container-fluid -->
  </section>
  <!-- /.content -->
</div>
<div class="loading" *ngIf="isLoading">Loading&#8230;</div>
